import parcels from "../images/parcels.jpg";
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRelayPoints } from "../core/apis/_requests.relays";
import { getTracking } from "../core/apis/_requests.tracking";
import { Options } from "../core/models/_models.general";
import { RelayPoint } from "../core/models/_models.relay";
import { setRelayPoints } from "../redux/features/relays";
import { setResultTracking } from "../redux/features/tracking";
import Select from "react-select";
import TrackingTimeline from "./TrackingTimeline";

const Introduction = () => {

  const dispatch = useDispatch();
  const [relaySearch, setRelaySearch] = useState<string>("");
  const [trackingNumber, setTrackingNumber] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [options, setOptions] = useState<Options>([]);
  const relayState = useSelector((state: any) => state.relays.relayState);

  const handleTrack = async (id: string) => {
    await getTracking(id).then((res) => {
      dispatch(setResultTracking(res));
    });
  };

  const getAllRelays = async () => {
    await getRelayPoints().then((res) => {
      dispatch(setRelayPoints(res));
    });
  };

  useEffect(() => {
    getAllRelays();
  }, []);


  const handleSearchRelay = (e: any) => {
    e.preventDefault();
    if (relaySearch && relaySearch.length > 0) {
      window.open(
        "https://www.google.com/maps/search/graiet+" + relaySearch.substring(5)
      );
    } else {
      window.open("https://www.google.com/maps/search/graiet+magasin");
    }
  };

  useEffect(() => {
    const options: Options = [];
    relayState?.map((item: RelayPoint) =>
      options.push({
        value: item.id!,
        label: item.postal_code + " " + item.city + "-" + item.governorate,
      })
    );
    setOptions(options);
  }, [relayState]);



  return (
  <><div className={""}>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-5 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 lg:w-1/2 w-full">
              <div className="h-full bg-gray-100 shadow-lg px-8 pt-4 pb-8 rounded-lg overflow-hidden text-center relative">
                <div className="flex container w-full items-center">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2 w-24 h-24"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M22 9.00002V15C22 17.5 21.5 19.25 20.38 20.38L14 14L21.73 6.27002C21.91 7.06002 22 7.96002 22 9.00002Z"
                        stroke="#0e7490"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                      <path
                        d="M21.73 6.27L6.26999 21.73C3.25999 21.04 2 18.96 2 15V9C2 4 4 2 9 2H15C18.96 2 21.04 3.26 21.73 6.27Z"
                        stroke="#0e7490"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                      <path
                        d="M20.38 20.38C19.25 21.5 17.5 22 15 22H9.00003C7.96003 22 7.06002 21.91 6.27002 21.73L14 14L20.38 20.38Z"
                        stroke="#0e7490"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                      <path
                        d="M6.24002 7.97997C6.92002 5.04997 11.32 5.04997 12 7.97997C12.39 9.69997 11.31 11.16 10.36 12.06C9.67001 12.72 8.58003 12.72 7.88003 12.06C6.93003 11.16 5.84002 9.69997 6.24002 7.97997Z"
                        stroke="#0e7490"
                        strokeWidth="1.5"
                      ></path>{" "}
                      <path
                        d="M9.0946 8.69995H9.10359"
                        stroke="#0e7490"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                  <div className="w-full text-center">
                    <h1 className="title-font font-condensed sm:text-4xl text-xl font-medium text-cyan-700">
                      Suivre mon colis
                    </h1>
                  </div>
                </div>
                <div className="grid grid-rows-2 gap-4">
                  <div className="row-span-1 ">
                    <input
                      type="text"
                      className=" rounded-lg border-2 border-cyan-400 p-2 w-full"
                      placeholder="Entrer votre numéro de suivi"
                      onChange={(e) => {
                        setTrackingNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row-span-1 ">
                    <button
                      className="rounded-lg border-2 text-white bg-cyan-700 p-2 w-full"
                      onClick={() => setShowModal(true)}
                    >
                      Suivre
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {showModal ? (
                <TrackingTimeline closeModal={() => setShowModal(false)} trackingNumber={trackingNumber} className="bg-opacity-60 justify-center items-center flex overflow-x-hidden inset-0 z-50 outline-none focus:outline-none" />
            ):null}
            
            <div className="p-4 lg:w-1/2 w-full">
              <div className="h-full bg-gray-100 shadow-lg px-8 pt-4 pb-8 rounded-lg overflow-hidden text-center relative">
                <div className="flex container w-full items-center">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2 w-24 h-24"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        opacity="0.4"
                        d="M14 5H20"
                        stroke="#f97316"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                      <path
                        opacity="0.4"
                        d="M14 8H17"
                        stroke="#f97316"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                      <path
                        d="M21 11.5C21 16.75 16.75 21 11.5 21C6.25 21 2 16.75 2 11.5C2 6.25 6.25 2 11.5 2"
                        stroke="#f97316"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                      <path
                        opacity="0.4"
                        d="M22 22L20 20"
                        stroke="#f97316"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                  <div className="w-full text-center">
                    <h1 className="title-font font-condensed sm:text-4xl text-xl font-medium text-orange-500">
                      Trouver un point relais
                    </h1>
                  </div>
                </div>
                <div className="grid grid-rows-2 gap-4">
                  <div className="row-span-1 ">
                  <Select
                      className="rounded-lg border-2 border-orange-700 p-2 w-full text-left "
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: 0,
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#c2410c"
                            : state.isFocused
                            ? "#ffedd5"
                            : "white",
                          color: state.isSelected ? "#ffffff" : "#2d3748",
                          ":active": {
                            ...provided[":active"],
                            backgroundColor: "#edf2f7",
                          },
                        }),
                      }}
                      options={options}
                      menuPortalTarget={document.body}
                      hideSelectedOptions={false}
                      name="relayPointSelect"
                      onChange={(e: any) => setRelaySearch(e?.label)}
                      placeholder="rechercher un point relais"
                    />
                  </div>

                  <div className="row-span-1 ">
                    <button
                      className="rounded-lg border-2 text-white bg-orange-500 p-2 w-full"
                       onClick={handleSearchRelay}
                    >
                      Rechercher
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
    </>
  );
};

export default Introduction;
