import { createSlice } from "@reduxjs/toolkit";
import { initialTrackingState } from "../../core/models/_models.tracking";

const trackingSlice = createSlice({
  name: "tracking",
  initialState: {
    trackingState: initialTrackingState,
  },
  reducers: {
    setResultTracking: (state, action) => {
      state.trackingState = action.payload;
    },
    emptyTracking: (state) => {
      state.trackingState = initialTrackingState;
    },
  },
});

export const { setResultTracking, emptyTracking } = trackingSlice.actions;

export default trackingSlice.reducer;
