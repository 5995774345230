export type transfer = {
type: string,
governorate: string,
date: string,
source: string,
mobile: string,
destination?: string,
src_mobile?: string,
dest_mobile?:string,
};


export type PositionResponse = {
  nb_jrs?: string,
  pick_up_at?: string,
  source: { governorate?: string, date?: string, adresse?: string, mobile?: string, firstname?: string, lastname?: string };
  destination: { governorate?: string, date?: string, adresse?: string, phone_number?: string, firstname?: string, lastname?: string };
  transfers: transfer[];
};

export const initialTrackingState: PositionResponse = {
  source: { governorate: undefined },
  destination: { governorate: undefined },
  transfers: [],
};
