type relayType = "dp" | "ad" | "mg";

export type RelayPoint = {
  id?: number;
  name: string;
  label?: string;
  governorate: string;
  address: string;
  postal_code: string;
  city: string;
  mobile: string;
  supplement?: string;
  phone_number?: string;
  email?: string;
  created_at?: string;
  updated_at?: string;
  type?: relayType;
  active?: boolean;
};

export type RelayPointAllQueryResponse = Array<RelayPoint>;

export const initialRelayState: RelayPointAllQueryResponse = [];
