import axios, { AxiosResponse } from "axios";
import { RelayPointAllQueryResponse } from "../models/_models.relay";

const RELAY_URL = `https://dev-api.logiteam.tn/api/relayListe`;

const getRelayPoints = (): Promise<RelayPointAllQueryResponse> => {
  return axios
    .get(RELAY_URL) 
    .then(
      (response: AxiosResponse<RelayPointAllQueryResponse>) => response.data
    );
};

export { getRelayPoints };
