import { createSlice } from "@reduxjs/toolkit";
import { initialRelayState } from "../../core/models/_models.relay";

const trackingSlice = createSlice({
  name: "tracking",
  initialState: {
    relayState: initialRelayState,
  },
  reducers: {
    setRelayPoints: (state, action) => {
      state.relayState = action.payload;
    },
    emtyRelayPoints: (state) => {
      state.relayState = initialRelayState;
    },
  },
});

export const { setRelayPoints, emtyRelayPoints } = trackingSlice.actions;

export default trackingSlice.reducer;
