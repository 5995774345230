import { FC, useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import { PositionResponse, transfer } from "../core/models/_models.tracking";
import { getTracking } from "../core/apis/_requests.tracking";

type Props = {
  className?: string;
  trackingNumber?: string
  closeModal: () => void;

};

const TrackingTimeline: FC<Props> = ({ className, trackingNumber, closeModal}) => {
  const [tracking, setTracking] = useState<PositionResponse>(
    {} as PositionResponse
  );

  const trackingState = useSelector(
    (state: any) => state.tracking.trackingState
  );


  useEffect(() => {
    const fetchData = async () => {
      try {
        const respond = await getTracking(trackingNumber!);
        setTracking(respond);
      } catch (error) {
      }
    };
    fetchData();
  });

  return (
    <div>
      <div>
           <>
          <div
            className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative w-auto my-6 mx-auto max-w-3xl" style={{ width: '80%' }}>
              {/*content*/}
              <div className="border-0 rounded-lg shadow shadow-lg-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative  xs:mx-0 w-full bg-opacity-90 backdrop-blur bg-gray-100 rounded-lg shadow shadow-lg-lg ">
                <div className="flex items-start justify-between p-4 border-b rounded-t ">
            <h3 className="text-xl font-semibold text-cyan-600 ">
            Détail de suivi du colis
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-cyan-200 hover:text-gray-900 rounded-lg  p-1.5 inline-flex items-center ml-6"
              data-modal-hide="defaultModal"
              onClick={closeModal}
              
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-6 space-y-6">
          {!tracking ? (
    <div>
      <h3 className="font-medium leading-tight mb-4 text-center">Aucun colis </h3>
    </div>

  )
:
(      
<div >
  <div className="py-3 sm:max-w-xl sm:mx-auto w-full px-2 sm:px-0">

    <div className="relative text-gray-700 antialiased text-sm font-semibold">

      <div className="hidden sm:block w-1 bg-blue-300 absolute h-full transform -translate-x-1/2"></div>
      {tracking.source && ( 
      <div className="mt-6 sm:mt-0 sm:mb-12">
      <div className="flex flex-col sm:flex-row items-center">
        <div className="flex justify-end w-full mx-auto items-center">
          <div className="w-full  sm:pl-8">
            <div className="p-4 bg-white rounded shadow shadow-lg">
            <h3 className="font-medium leading-tight mb-4">{tracking.source.firstname} {tracking.source.lastname}</h3>
            <div className="flex space-x-2 ">
                  
                  <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                   <path clip-rule="evenodd" fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"></path>
                  </svg>
                           <p>{tracking.source.adresse}</p> 
                      </div>
              <div className="flex space-x-2  my-3">
                
              <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
           <path clip-rule="evenodd" fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"></path>
             </svg>
                   <p>{tracking.source.mobile}</p> 
              </div>
              <div className="flex space-x-2  my-3">
                
              <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
           <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z"></path>
             <path clip-rule="evenodd" fill-rule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"></path>
               </svg>
                     <p>Récolter le {tracking.pick_up_at?.split("T")[0]}</p> 
                </div>
            </div>
          </div>
        </div>
        <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
        <svg className="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
   <path clip-rule="evenodd" fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"></path>
 </svg>
        </div>
      </div>
    </div>
      )}
   {tracking.transfers?.length > 0 && 
                tracking.transfers.map((item: transfer, index) => (

    (index === tracking.transfers.length - 2 && item.type === 'Réception' && tracking.nb_jrs!=="")
    || (index === tracking.transfers.length - 1 && item.type === 'Réception' && tracking.nb_jrs!=="") ?
    <div className="mt-6 sm:mt-0 sm:mb-12">
    <div className="flex flex-col sm:flex-row items-center">
      <div className="flex justify-end w-full mx-auto items-center">
        <div className="w-full  sm:pl-8">
          
        <a
className="relative block p-8 overflow-hidden p-4 bg-white rounded shadow shadow-lg"
href=""
>
<header className="flex font-light text-sm mb-4">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 rotate-90 -ml-2"  viewBox="0 0 24 24" stroke="#0073e6">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
   </svg>
  <p>{item.date?.split("T")[0]}</p>
     </header>
<span
  className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-blue-500 to-green-500"
></span>

<h3 className="font-medium leading-tight mb-4">Réceptionnée à {item.source}</h3>
        {/* <p className="">{item.governorate} ,{item.mobile}</p> */}
        <div className="flex space-x-2 ">
              
        <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
         <path clip-rule="evenodd" fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"></path>
        </svg>
                 <p>{item.governorate}</p> 
            </div>
            {/* <div className="flex item-center justify-between mt-3">
            <div className="flex space-x-2  my-3">
              
              <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
           <path clip-rule="evenodd" fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"></path>
             </svg>
                   <p>{item.mobile}</p> 
              </div>
         <button className="px-3 py-2 bg-green-500 text-white rounded">Livraison dans {tracking.nb_jrs} jours</button>
      </div> */}
      <div className="flex space-x-2  my-3">
              
              <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
           <path clip-rule="evenodd" fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"></path>
             </svg>
                   <p>{item.mobile}</p> 
              </div>
          
      <div className="mt-10 mb-10">
      <p className="text-gray-600">Livraison en {tracking.nb_jrs} jours</p>
    </div>
</a>
        </div>
      </div>
      <div className="rounded-full bg-green-500 border-white border-4 w-8 h-8 absolute -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
      <svg aria-hidden="true" className="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
      </div>
    </div>
  </div>
  :       
    (item?.type==="Réception")?
      <div className="mt-6 sm:mt-0 sm:mb-12">
        <div className="flex flex-col sm:flex-row items-center">
          <div className="flex justify-end w-full mx-auto items-center">
            <div className="w-full  sm:pl-8">
              <div className="p-4 bg-white rounded shadow shadow-lg">
              <header className="flex font-light text-sm mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 rotate-90 -ml-2"  viewBox="0 0 24 24" stroke="#0073e6">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
       </svg>
      <p>{item.date?.split("T")[0]}</p>
         </header>
              <h3 className="font-medium leading-tight mb-4">Réceptionnée à {item.source}</h3>
            {/* <p className="">{item.governorate} ,{item.mobile}</p> */}
            <div className="flex space-x-2 ">
                  
            <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
             <path clip-rule="evenodd" fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"></path>
            </svg>
                     <p>{item.governorate}</p> 
                </div>
                <div className="flex space-x-2  my-3">
                  
                <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
             <path clip-rule="evenodd" fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"></path>
               </svg>
                     <p>{item.mobile}</p> 
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
          <svg aria-hidden="true" className="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
          </div>
        </div>
      </div>
      : 
      (item?.type==="Transfert")?
      <div className="mt-6 sm:mt-0 sm:mb-12">
        <div className="flex flex-col sm:flex-row items-center">
          <div className="flex justify-end w-full mx-auto items-center">
            <div className="w-full  sm:pl-8">
              <div className="p-4 bg-white rounded shadow shadow-lg">
              <header className="flex font-light text-sm mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 rotate-90 -ml-2"  viewBox="0 0 24 24" stroke="#0073e6">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
       </svg>
      <p>{item.date?.split("T")[0]}</p>
         </header>
              <h3 className="font-medium leading-tight mb-4">Transferée de {item.source} à {item.destination}</h3>
              <div className="flex space-x-2 ">
                  
                  <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                   <path clip-rule="evenodd" fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"></path>
                  </svg>
                           <p>{item.governorate}</p> 
                      </div>
                      <div className="flex space-x-2  my-3">
                        
                      <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                   <path clip-rule="evenodd" fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"></path>
                     </svg>
                           <p>{item.dest_mobile} ,{item.src_mobile} </p> 
                      </div>
              </div>
            </div>
          </div>
          <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
          <svg  className="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 116 0h3a.75.75 0 00.75-.75V15z"></path>
  <path d="M8.25 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zM15.75 6.75a.75.75 0 00-.75.75v11.25c0 .087.015.17.042.248a3 3 0 015.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 00-3.732-10.104 1.837 1.837 0 00-1.47-.725H15.75z"></path>
  <path d="M19.5 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"></path>
</svg>
          </div>
        </div>
      </div>
      :
      <div className="mt-6 sm:mt-0 sm:mb-12">
        <div className="flex flex-col sm:flex-row items-center">
          <div className="flex justify-end w-full mx-auto items-center">
            <div className="w-full  sm:pl-8">
              <div className="p-4 bg-white rounded shadow shadow-lg">
              <header className="flex font-light text-sm mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 rotate-90 -ml-2"  viewBox="0 0 24 24" stroke="#0073e6">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
       </svg>
      <p>{item.date?.split("T")[0]}</p>
         </header>
              <h3 className="font-medium leading-tight mb-4">Livrée à {item.source}</h3>
              <div className="flex space-x-2 ">
                  
                  <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                   <path clip-rule="evenodd" fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"></path>
                  </svg>
                           <p>{item.governorate}</p> 
                      </div>
                      <div className="flex space-x-2  my-3">
                        
                      <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                   <path clip-rule="evenodd" fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"></path>
                     </svg>
                           <p>{item.mobile}</p> 
                      </div>
              </div>
            </div>
          </div>
          <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
          <svg aria-hidden="true" className="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
          </div>
        </div>
      </div>
        ))}
    {tracking.destination && tracking.transfers[tracking.transfers.length-1]?.type!=="Livraison" && (
      <div className="mt-6 sm:mt-0 sm:mb-12">
        <div className="flex flex-col sm:flex-row items-center">
          <div className="flex justify-end w-full mx-auto items-center">
            <div className="w-full  sm:pl-8">
              <div className="p-4 bg-white rounded shadow shadow-lg">
              <h3 className="font-medium leading-tight mb-4">{tracking.destination.firstname} {tracking.destination.lastname}</h3>
            <div className="flex space-x-2 ">
                  
                  <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                   <path clip-rule="evenodd" fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"></path>
                  </svg>
                           <p>{tracking.destination.adresse}</p> 
                      </div>
                      <div className="flex space-x-2  my-3">
                        
                      <svg fill="currentColor" viewBox="0 0 24 24" className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                   <path clip-rule="evenodd" fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"></path>
                     </svg>
                           <p>{tracking.destination.phone_number}</p> 
                      </div>
              </div>
            </div>
          </div>
          <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute  -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
          <svg className="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path clip-rule="evenodd" fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"></path>
</svg>
          </div>
        </div>
      </div>
    )}



    </div>

  </div>
</div>
)
}


          </div>
           </div>
                {/*footer*/}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      </div>
      <div
        className='md:h-72 '
      />
    </div>
  );
};

export default TrackingTimeline;
