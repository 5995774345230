import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { MainParentLayout } from "./MainParentLayout";
import Main from "../pages/Home";
import Presentation from "../pages/Presentation";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Pricings from "../pages/Pricings";
import Services from "../pages/Services";
import Success from "../pages/Success";
import Annonce from "../pages/Annonce";


const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainParentLayout />}>
        <Route path="/" element={<Main />} />
        <Route path="/home" element={<Main />} />
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/about" element={<About />} />
        <Route path="/ads" element={<Contact />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<Pricings />} />
        <Route path="/services" element={<Services />} />
        <Route path="/success" element={<Success />} />
        {/* <Route path="/Annonce" element={<Annonce />} /> */}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
