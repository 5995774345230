const Pricings = () => {
  return (
<div className="container mx-auto px-20 mt-20">
<div className="mb-10 w-full lg:mb-0 lg:w-1/2">
            <h1 className="title-font mb-2 text-2xl font-medium text-gray-900 sm:text-3xl">
             Pourquoi logiteam
            </h1>
            <div className="h-1 w-20 rounded bg-orange-500"></div>
          </div>
<div className="bg-gray">
  <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 pt-10 lg:pt-10 lg:pb-20 text-center" >
            
  <div className=" p-6 bg-white rounded shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded sm:px-10">
              
    <div className="mb-5">
                
      <svg className="hi-outline hi-template inline-block w-12 h-12" stroke="currentColor" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"></path>
      </svg>
              
    </div>
              
    <h3 className="text-lg font-bold mb-4">Economique</h3>
              
    <p className=" leading-6 text-gray-600">
    Nos services sont jusqu'à 50% moins chers que les solutions d'envoi à domicile.</p>            
  </div>
            
  <div className="p-6 bg-orange-500 text-white rounded shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded sm:px-10">
              
    <div className="mb-5">
                
      <svg className="hi-outline hi-cube inline-block w-12 h-12" stroke="currentColor" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"></path>
      </svg>
              
    </div>
              
    <h3 className="text-lg font-bold mb-4"> Eco Responsable</h3>
              
    <p className=" leading-6">
    Choisir Logiteam Relais c'est limiter l'impact environnemantal de ses colis et soutenir l'économie locale.</p>
            
  </div>
            
  <div className="p-6 bg-white rounded shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded sm:px-10">
              
    <div className="mb-5" >             
      <svg className="hi-outline hi-cog inline-block w-12 h-12" stroke="currentColor" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
      </svg>              
    </div>
              
    <h3 className="text-lg font-bold mb-4">Pratique</h3>
              
    <p className=" leading-6 text-gray-600">Une solution informatique simplifiée et modérée afin de faire un suivi optimal et instantanée des colis.</p>
            
  </div>
          
</div>
</div>
</div>
  );
};

export default Pricings;
