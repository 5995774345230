import Logiteam from "../images/logiteam_logo.png";
import {BsFacebook} from "react-icons/bs";
import {BsInstagram} from "react-icons/bs";
import {BsLinkedin} from "react-icons/bs";
import {AiFillPhone} from "react-icons/ai";
import {AiOutlineMail} from "react-icons/ai";
import {GoLocation} from "react-icons/go";
export const Footer = () => {
  
  return (
    <footer className="text-gray-600 body-font"  style={{  justifyContent: 'center' }}>
      <div className=" px-5 py-24 bg-gradient-to-r from-orange-300 to bg-orange-400">
        <div className="flex flex-wrap md:text-left text-center mx-4">
          <div className="lg:w-1/6 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
            <img src={Logiteam} alt="Logo" className="w-10 h-5" /> 
            </h2>
            <nav className="list-none mb-10">
             <p>Logiteam est une entreprise de logistique de colis utilise des points relais pour assurer une livraison rapide et sûre de vos envois</p>
            </nav>
          </div>
          <div className="lg:w-1/6 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              liens
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a href="/" className="text-gray-600 hover:text-gray-800">Accueil</a>
              </li>
              <li>
                <a href="/presentation" className="text-gray-600 hover:text-gray-800"> Présentation</a>
              </li>
              <li>
                <a href="/services" className="text-gray-600 hover:text-gray-800"> Pourquoi logiteam?</a>
              </li>
              <li>
                <a href="/contact" className="text-gray-600 hover:text-gray-800">Devenir partenaire</a>
              </li>
              <li>
                <a href="/about" className="text-gray-600 hover:text-gray-800">À propos </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/6 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
            Rejoinez nous 
            </h2>
            <div className="row d-flex justify-content-center">
          <div className="text-center">
            
           <ul className="list-unstyled list-inline">
            <li className="list-none mb-1 d-flex align-items-center lg:ml-10 ">
            <a
                href="https://www.facebook.com/logiteam.tn"
                className="text-gray-500 "
            > 
              <BsFacebook className=" inline lg:block"/>
            </a>
            </li><br></br>
            <li className="list-none mb-1 d-flex align-items-center lg:ml-10">
              <a href="https://www.instagram.com/logiteam.tn/" className="text-dark">
                    <BsInstagram className=" inline lg:block"/></a>
              </li><br></br>
              <li className="list-none mb-1 d-flex align-items-center lg:ml-10">
              <a href="https://www.linkedin.com/company/logiteam-tunisie/" className="text-dark">
                    <BsLinkedin className=" inline lg:block"/>
              </a>
              </li><br></br>
              
               
             
            </ul> 
           </div>
            </div>
            <div className="lg:w-1/6 md:w-1/2 w-full px-4">
         </div> 
          </div>
          <div className="lg:w-1/6 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
             heures de travail
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a className="text-gray-600 hover:text-gray-800">Lundi de 8h</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">Mardi de 8h</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">Mercredi de 8h</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">Jeudi de 8h</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">Vendredi de 8h</a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800">Samedi de 8h</a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/6 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
             Contactez nous à
            </h2>
            <nav className="list-none mb-5">
      
              <p>
            <li className="lg:flex items-center">
              <GoLocation className="mr-2 inline lg:block"/> M'saken
            </li><br></br>
              </p>
            {/* <li className="flex items-center">
              <AiOutlineMail className="mr-2"/>logiteam@gmail.com
            </li> */}
             <p>
            <li onClick={()=>window.open(`tel:+21627341038`)} className="lg:flex items-center cursor-pointer">
              <AiFillPhone className="mr-2 inline lg:block" />+216 27 341 038
            </li><br></br>
            <li onClick={()=>window.open(`tel:+21629430430`)} className="lg:flex items-center cursor-pointer">
              <AiFillPhone className="mr-2 inline lg:block" />+216 29 430 430
            </li><br></br>
             </p>
              
              
            </nav>
          </div>
          <div className="lg:w-1/6 md:w-1/2 w-full px-4">
          
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200">
        <div className="container px-5 py-8 flex flex-wrap mx-auto items-center">
          
            <div className="relative sm:w-64 w-40 sm:mr-4 mr-2">
        </div>
          <span className="inline-flex lg:ml-auto lg:mt-0 mt-6 w-full justify-center md:justify-start md:w-auto">
            
          </span>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-500 text-sm text-center sm:text-left">
            © 2022 Logiteam —
            <a
              href="https://facebook.com/logiteam.tn"
              className="text-gray-600 ml-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Logiteam
            </a>
          </p>
          
        </div>
      </div>
      
    </footer>
  );
};
