import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import Cards from "../components/Cards";
import Pircings from "../pages/Pricings";
import Introduction from "../components/Introduction";
// import RelayPoints from "../components/RelayPoints";
import ServicesIntroduction from "../components/ServicesIntroduction";
import { TrackingSearch } from "../components/IntroSearch";
import TrackingTimeline from "../components/TrackingTimeline";
import Partners from "../components/Partners"
import { useState } from "react";


const Home = () => {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let tn = params.get("tn");


  return (
    <div className="relative ">
      <div className="relative sm:bg-logiteambanner bg-none sm:bg-contain bg-local bg-top bg-no-repeat">
        <div className=" relative mx-auto max-w-7xl py-24 px-4 sm:py-9 sm:px-6 lg:px-8">
          <div className="sm:w-auto md:w-2/6">
            <h1 className="text-start text-4xl font-bold text-orange-500">
              Bienvenue à <span className="text-cyan-800">Logi</span>
              team
            </h1>
            <p className="mt-2 text-start text-xl text-cyan-900 ">
              Vitesse, Fiabilité et Sécurité.
            </p>
          </div>
        </div>
        <TrackingSearch className=" mx-5 sm:mx-20" id={tn} />
        <div
        className='md:h-72 '
      />
      </div>
      <Introduction />
      <ServicesIntroduction />
      <section className="overflow-hidden mt-20">
        <AnimatePresence>
          <motion.h1
            initial={{ x: "-100vw" }}
            animate={{ x: "100vw" }}
            transition={{ duration: 12, repeat: Infinity }}
            exit={{ x: "100vw" }}
            className="text-6xl text-center font-bold text-gray-900">
            Logiteam a tous vos services
          </motion.h1>
        </AnimatePresence>
      </section>
      <Pircings />
      <Partners />
      {/* <Annonce/> */}
    </div>

  );
};

export default Home;
