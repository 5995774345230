import React from 'react'
import Logiteam from "../images/logiteam_banner.png";
const About = () => {
  return (
    <div>
      <section className="body-font  text-gray-600 mb-4">
        <div className="container mx-auto w-min   ">
          <div className="mx-auto border px-5 py-24 rounded-lg shadow-lg text-center lg:w-3/4 xl:w-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="mb-8 inline-block h-8 w-8 text-gray-400"
              viewBox="0 0 975.036 975.036"
            >
              <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
            </svg>
           
            <p className="text-lg leading-relaxed">
            Logiteam est une entreprise de logistique de colis créée en 2021. Nous sommes fiers de faire partie du groupe Graiet, une entreprise avec une expérience de 65 ans dans la vente d'électronique et de mobilier. Cette relation nous permet de bénéficier de l'expertise et des ressources d'une société bien établie, tout en nous concentrant spécifiquement sur la logistique de colis.<br/><br/>
            <span className='text-blue-600'>Grâce à notre partenariat avec Graiet,</span> nous facilitons la collaboration avec le vaste réseau de magasins Graiet en Tunisie. Cela nous permet de tirer parti de leur présence géographique étendue et d'offrir un service de livraison encore plus pratique et efficace à nos clients.<br/><br/>
            Nous disposons actuellement de plus de 53 points relais Graiet répartis stratégiquement dans tout le pays. Ces points relais sont soigneusement sélectionnés pour offrir un maximum de commodité et de proximité à nos clients. Que vous soyez à Tunis, Sousse, Sfax, Gabez, Bizerte, le Kef ou tout autre endroit en Tunisie, il y a toujours un point relais Graiet à proximité pour recevoir vos colis.<br/><br/>
            Notre objectif est de fournir une logistique de colis de qualité supérieure tout en offrant un service client exceptionnel. Nous nous engageons à assurer une livraison rapide, sûre et fiable de vos envois, en veillant à ce qu'ils arrivent à destination dans les délais convenus et en bon état.<br/><br/>
            En choisissant Logiteam, vous bénéficiez de l'expérience d'un groupe solide, ainsi que de notre connaissance approfondie de la logistique de colis. Nous sommes constamment à la recherche de moyens d'améliorer nos services et de répondre aux besoins changeants de nos clients.<br/><br/>
            Nous tenons à remercier nos clients et nos partenaires pour leur confiance et leur soutien continu. C'est grâce à vous que nous continuons à nous développer et à proposer des solutions logistiques de premier ordre.<br/><br/>
            Contactez-nous dès aujourd'hui pour découvrir comment nous pouvons répondre à vos besoins logistiques et devenir votre partenaire privilégié pour la livraison de vos colis en Tunisie.<br/><br/>
            Merci d'avoir choisi Logiteam !<br/><br/>
            L'équipe <span className='text-orange-500'>Logiteam</span><br/><br/>
            </p>
            <span className="mt-8 mb-6 inline-block h-1 w-10 rounded bg-cyan-500"></span>
            
            
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
