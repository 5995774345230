import React from "react";
import dreams from "../images/dreams.png";
import graiet from "../images/graiet.png";
import likidou from "../images/likidou.png";
import avon from "../images/avon.png";
import IMG from "../images/IMG.png";
import CL from "../images/CL.png";
import lg from "../images/lg.png";
import mgd from "../images/mgd.png";
import SAV from "../images/SAV.png";
import smag  from "../images/smag.png"; 
import theplace  from "../images/thePlace.png";
import smma  from "../images/smma.png";

const Partners= () => {
  return (
    <section className="body-font">
     <div className="flex flex-wrap items-center justify-center pt-4 sm:pt-4">
        <span className="m-12 block">
          <img src={dreams} alt="client logo" className="mx-auto block h-28 w-auto"/>
        </span>
        <span className="m-12 block">
          <img src={graiet} alt="client logo" className="mx-auto block h-28 w-auto"/>
        </span>

        <span className="m-12 block">
          <img src={avon} alt="client logo" className="mx-auto block h-28 w-auto"/>
        </span>

        <span className="m-12 block">
          <img src={IMG} alt="client logo" className="mx-auto block h-28 w-auto"/>
        </span>

        <span className="m-12 block">
          <img src={theplace} alt="client logo" className="mx-auto block h-28 w-auto"/>
        </span>
      </div>
    </section>
    
  );
};

export default Partners;
