import React, { useState } from 'react'
import image from '../images/delivery.png';
import TruckDelivery from '../images/TruckDelivery.png';
import { useNavigate } from 'react-router-dom';
import { contact } from "../core/models/_model.contact";
import { createContact } from '../core/apis/_requests.contact';

const Services = () => {

  const [contact, setContact] = useState<contact>();

  const navigate = useNavigate()
  const handleSubmit = async () => {
    await createContact(contact!)
      .then((res) => {
        navigate('/success')
        console.log(res)
      })
      .catch(error => {
        console.log(error)
      });
  }

  return (

    <>
      <section className=" bg-white pt-10">
        <div className="items-center flex flex-wrap mb-20 ">
          <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div className="md:pr-12">

              <div className="text">
                <h2 className="my-4 font-bold text-3xl  sm:text-4xl ">Chez <span className="text-orange-600">Logiteam</span>
                </h2>

                <div className="flex items-center mt-10 text-lg">
                  <span className="bg-orange-600 rounded-full p-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </span>
                  <span className="mx-4 text-gray-700 dark:text-gray-300"> Nous proposons une gamme complète de services pour répondre à vos besoins logistiques.</span>
                </div>

                <div className="flex items-center mt-10 text-lg">
                  <span className="bg-orange-600 rounded-full p-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </span>
                  <span className="mx-4 text-gray-700 dark:text-gray-300"> Notre objectif est de rendre le processus d'expédition et de livraison aussi pratique et efficace que possible.</span>
                </div>
                <div className="flex items-center mt-10 text-lg">
                  <span className="bg-orange-600 rounded-full p-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </span>
                  <span className="mx-4 text-gray-700 dark:text-gray-300">  Nous nous efforçons de fournir une expérience de livraison exceptionnelle, de la collecte de vos colis jusqu'à leur livraison finale. </span>
                </div>
              </div>
            </div></div><div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
            <img alt="..." className="max-w-full rounded-lg shadow-xl" src={TruckDelivery} /></div></div>


        <section className="bg-slate-50 py-24">
    <div className="container mx-auto px-4">
        <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Nos Services</h2>
            <p className="text-gray-600 mb-12">Chez Logiteam Nous nous engageons à offrir des services de qualité supérieure à nos clients. Quels que soient vos besoins logistiques, notre équipe dévouée est là pour vous aider.
            </p>
        </div>


        <div className="grid grid-cols-1 sm:grid-cols-2 rounded gap-8  p-10">
            <div className=" p-6 bg-white rounded shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:rounded sm:px-10">

              <div className="mb-5">
                <svg className="hi-outline hi-template inline-block w-12 h-12" viewBox="0 0 24 24" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1="18" y1="6" x2="18" y2="6.01" />  <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />  <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />  <line x1="9" y1="4" x2="9" y2="17" />  <line x1="15" y1="15" x2="15" y2="20" /></svg>

              </div>
              <h3 className="text-lg font-bold mb-4">Service Pick-up  </h3>

              <p className=" leading-6 text-gray-600">
                Notre service Pick-up vous permet de planifier l'enlèvement de vos colis à l'endroit de votre choix. Que vous soyez un particulier ou une entreprise, notre équipe viendra récupérer vos colis. Plus besoin de vous déplacer, nous nous occupons de tout !</p>
            </div>

            <div className=" p-6 bg-white rounded shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:rounded sm:px-10">

              <div className="mb-5">

                <svg className="hi-outline hi-template inline-block w-12 h-12" viewBox="0 0 24 24" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="7" cy="17" r="2" />  <circle cx="17" cy="17" r="2" />  <path d="M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />  <line x1="3" y1="9" x2="7" y2="9" /></svg>

              </div>

              <h3 className="text-lg font-bold mb-4">Service Livraison </h3>

              <p className=" leading-6 text-gray-600">
                Notre service Livraison assure une livraison rapide et fiable de vos colis. Grâce à notre réseau étendu de points relais stratégiquement situés, nous pouvons livrer vos envois dans les délais convenus. Que ce soit pour des livraisons locales, nationales ou internationales, vous pouvez compter sur nous pour une livraison en toute sécurité.</p>
            </div>

            <div className=" p-6 bg-white rounded shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:rounded sm:px-10">

              <div className="mb-5">

                <svg className="hi-outline hi-template inline-block w-12 h-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>

              </div>

              <h3 className="text-lg font-bold mb-4">Service Suivi en ligne </h3>

              <p className=" leading-6 text-gray-600">
                Avec notre service Suivi en ligne, vous pouvez suivre l'acheminement de vos colis à tout moment. Grâce à notre système de suivi avancé, vous aurez accès aux informations en temps réel sur l'état de votre envoi. Cela vous permet de rester informé de la progression de votre livraison et de planifier en conséquence.</p>
            </div>

            <div className=" p-6 bg-white rounded shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:rounded sm:px-10">

              <div className="mb-5">
                <svg className="hi-outline hi-template inline-block w-12 h-12" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />  <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" /></svg>

              </div>

              <h3 className="text-lg font-bold mb-4">Service collecte de paiement COD (Cash On Delivery) </h3>

              <p className=" leading-6 text-gray-600">
                Nous comprenons que certains envois nécessitent un paiement à la livraison. Notre service collecte de paiement COD vous permet d'accepter les paiements en espèces au moment de la livraison. Cela facilite les transactions pour les marchands et offre une plus grande flexibilité aux destinataires.</p>
            </div>
          </div>
    </div>
</section>
        {/* <div>
          <div className="relative pt-20">
            <div aria-hidden="true" className="hidden sm:block">
              <svg className="absolute top-8 left-1/2 -ml-3" width="404" height="392" fill="none" viewBox="0 0 404 392">
                <defs>
                  <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20"
                    patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"></rect>
                  </pattern>
                </defs>
                <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"></rect>
              </svg>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="relative rounded-2xl px-6 py-10 bg-orange-500 overflow-hidden shadow-xl sm:px-12 sm:py-20">
                <div className="relative flex flex-col">
                  <div className="sm:text-center">
                    <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">Contactez-nous</h2>
                    <p className="mt-6 mx-auto max-w-2xl text-lg text-gray-100"> Contactez-nous dès aujourd'hui pour discuter de vos besoins spécifiques et découvrez comment nos services peuvent vous aider à optimiser vos opérations logistiques. Faites confiance à Logiteam pour une logistique de colis fiable, rapide et sûre ! </p>
                  </div>
                  <a href="/contact"
                    className="w-auto inline-block mt-5 mx-auto rounded-md border border-transparent px-5 py-3 bg-orange-900 text-base font-medium text-white shadow hover:bg-orange sm:px-10">
                    Contact →
                  </a>


                </div>
              </div>
            </div>
          </div>
        </div> */}

<section className="py-24 mt-0 relative bg-[url('../images/contact.png')] bg-center bg-cover">
            <div className="absolute inset-0 bg-black opacity-80"></div>
            <div className="relative">
                <div className="grid grid-cols-1 pb-8 text-center">
                    {/* <h3 className="mb-6 md:text-3xl text-2xl text-white font-medium">Contactez-nous</h3>

                    <p className="text-white opacity-50 max-w-xl text-xl mx-auto"> Contactez-nous dès aujourd'hui pour discuter de vos besoins spécifiques et découvrez comment nos services peuvent vous aider à optimiser vos opérations logistiques. Faites confiance à Logiteam pour une logistique de colis fiable, rapide et sûre !</p> */}   
                     <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-20">           
                    <div >
    <div className="w-full">
        <div className="bg-white p-10 rounded-lg shadow">
            <div>
            <div className="flex flex-wrap -m-2">
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    htmlFor="name"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Nom
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    onChange={(e) => {
                      setContact((prevFormData) => ({
                        ...prevFormData,
                        name: e.target.value
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    htmlFor="email"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    onChange={(e) => {
                      setContact((prevFormData) => ({
                        ...prevFormData,
                        email: e.target.value
                      }));
                    }}
                  
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    htmlFor="message"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-200 h-32 text-lg outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    onChange={(e) => {
                      setContact((prevFormData) => ({
                        ...prevFormData,
                        message: e.target.value
                      }));
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="p-2 w-full">
                {/* <button onClick={handleSubmit} className="flex mx-auto text-white bg-orange-500 border-0 py-2 px-8 focus:outline-none hover:bg-orange-600 rounded text-lg">
                  Envoyer
                </button> */}
                <button  onClick={handleSubmit} className='flex mx-auto text-white border-0 py-2 px-8 focus:outline-none  group font-medium tracking-wide select-none text-base relative  justify-center cursor-pointer h-12 border-2 border-solid rounded-md overflow-hidden z-10 transition-all duration-300 ease-in-out outline-0 bg-orange-500 text-white border-orange-500 hover:text-orange-500 focus:text-orange-500'>
                  <strong className='font-medium'>Contact</strong>
                  <span className='absolute bg-white bottom-0 w-0 left-1/2 h-full -translate-x-1/2 transition-all ease-in-out duration-300 group-hover:w-[105%] -z-[1] group-focus:w-[105%]'></span>
                </button>

              </div>
            </div>
            </div>
        </div>
    </div>
</div>
<div className="text-white rounded">
                <h2 className="font-bold text-2xl">Contactez-nous</h2>
                <ul className="list-disc mt-4 list-inside">
                Contactez-nous dès aujourd'hui pour discuter de vos besoins spécifiques et découvrez comment nos services peuvent vous aider à optimiser vos opérations logistiques. Faites confiance à Logiteam pour une logistique de colis fiable, rapide et sûre !
                </ul>
                <div className="grid grid-cols-1 sm:grid-cols-2 rounded gap-10">
                <div className="flex items-center mt-10 text-lg">
                <span className=" bg-orange-500 text-white p-3 rounded-full">
                <svg width="10" height="10" className=" w-5 h-5"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
            </span>
                  <span className="mx-4 font-bold">+216 27 341 038</span>
                </div>

                <div className="flex items-center mt-10 text-lg">
                <span className=" bg-orange-500 text-white p-3 rounded-full">
                <svg width="10" height="10" className=" w-5 h-5"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
            </span>
                  <span className="mx-4 font-bold">+216 29 430 430</span>
                </div>
                </div>
            </div>
</div> 
                </div>
            </div>
        </section>

        
      </section>

    </>
  );
};

export default Services;
