import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user";
import trackingReducer from "./features/tracking";
import relaysReducer from "./features/relays";

export default configureStore({
  reducer: {
    user: userReducer,
    tracking: trackingReducer,
    relays: relaysReducer,
  },
});
