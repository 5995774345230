import { useState } from "react";
import { createContact } from "../core/apis/_requests.contact";
import { contact } from "../core/models/_model.contact";
import { useNavigate } from "react-router";


const Contact = () => {

  const [contact, setContact] = useState<contact>();

  const navigate = useNavigate()
  const handleSubmit = async () => {
    await createContact(contact!)
      .then((res) => {
        navigate('/success')
        console.log(res)
      })
      .catch(error => {
        console.log(error)
      });
  }


  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2 mb-5 ">

      <section className="text-gray-600 body-font relative max-w-max shadow-lg rounded border">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-12">
            <div className="flex justify-center">
            </div>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Contacter nous
            </p>
          </div>
          <div className="lg:w-1/2 md:w-2/3 mx-auto">
            <div className="flex flex-wrap -m-2">
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    htmlFor="name"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Nom
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    onChange={(e) => {
                      setContact((prevFormData) => ({
                        ...prevFormData,
                        name: e.target.value
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    htmlFor="email"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    onChange={(e) => {
                      setContact((prevFormData) => ({
                        ...prevFormData,
                        email: e.target.value
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    htmlFor="message"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-200 h-32 text-lg outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    onChange={(e) => {
                      setContact((prevFormData) => ({
                        ...prevFormData,
                        message: e.target.value
                      }));
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="p-2 w-full">
                {/* <button onClick={handleSubmit} className="flex mx-auto text-white bg-orange-500 border-0 py-2 px-8 focus:outline-none hover:bg-orange-600 rounded text-lg">
                  Envoyer
                </button> */}
                <button onClick={handleSubmit} className='flex mx-auto text-white border-0 py-2 px-8 focus:outline-none  group font-medium tracking-wide select-none text-base relative  justify-center cursor-pointer h-12 border-2 border-solid rounded-md overflow-hidden z-10 transition-all duration-300 ease-in-out outline-0 bg-orange-500 text-white border-orange-500 hover:text-orange-500 focus:text-orange-500'>
                  <strong className='font-medium'>Contact</strong>
                  <span className='absolute bg-white bottom-0 w-0 left-1/2 h-full -translate-x-1/2 transition-all ease-in-out duration-300 group-hover:w-[105%] -z-[1] group-focus:w-[105%]'></span>
                </button>

              </div>
              <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                {/* <a className="text-orange-500">logiteam@email.com</a> */}
                <p className="leading-normal my-5">

                  <br />

                </p>
                <span className="inline-flex">
                  <a
                    href="https://www.facebook.com/logiteam.tn"
                    className="text-gray-500"
                  >
                    <svg
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                  </a>
                  <a className="ml-4 text-gray-500"
                    href="https://www.instagram.com/logiteam.tn/"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <rect
                        width="20"
                        height="20"
                        x="2"
                        y="2"
                        rx="5"
                        ry="5"
                      ></rect>
                      <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                    </svg>
                  </a>
                  <a className="ml-4 text-gray-500"
                    href="https://www.linkedin.com/company/logiteam-tunisie/"
                  >
                    <svg
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="0"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="none"
                        d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                      ></path>
                      <circle cx="4" cy="4" r="2" stroke="none"></circle>
                    </svg>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


  );
};

export default Contact;
