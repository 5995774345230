import axios, { AxiosResponse } from "axios";
import { PositionResponse } from "../models/_models.tracking";

const TRACKING_URL = `https://api.logiteam.tn/api/position`;

const getTracking = (trackingNumber: string): Promise<PositionResponse> => {
  return axios
    .get(TRACKING_URL, {
      params: {
        tracking_number: trackingNumber,
      },
    })
    .then((response: AxiosResponse<PositionResponse>) => response.data);
};

export { getTracking };
