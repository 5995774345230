import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";

const App = () => {
  const { PUBLIC_URL } = process.env;
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <AppRoutes />
    </BrowserRouter>

  );
};

export default App;
