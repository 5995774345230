
import { useNavigate } from 'react-router-dom';
import image from '../images/delivery.png';
import TruckPackages from '../images/TruckPackages.jpg';
import { useState } from 'react';
import { contact } from "../core/models/_model.contact";
import { createContact } from '../core/apis/_requests.contact';
const Presentation = () => {

  const [contact, setContact] = useState<contact>();

  const navigate = useNavigate()
  const handleSubmit = async () => {
    await createContact(contact!)
      .then((res) => {
        navigate('/success')
        console.log(res)
      })
      .catch(error => {
        console.log(error)
      });
  }

  return (

    <>
      <section className=" bg-white">
        <div className="flex flex-col mx-auto">
          <div className="bg-slate-50 py-24 flex flex-wrap items-center px-8 xl:px-36"><div className="w-full lg:w-1/2 mb-6 lg:mb-0">
            <h2 className="text-4xl font-bold font-heading wow animate__animated animate__fadeIn" data-wow-delay=".1s">
              <span><span className="text-orange-500">Logiteam</span> est une entreprise de logistique de colis spécialisée dans l'utilisation de points relais</span><br />
            </h2></div><div className="w-full lg:w-1/2">
              <p className="lg:pl-16 text-orangeGray-400 leading-loose wow animate__animated animate__fadeIn" data-wow-delay=".4s">
                Nous comprenons l'importance d'une livraison rapide et pratique de vos envois, c'est pourquoi nous avons développé un réseau de points relais efficace et bien situés pour répondre à vos besoins.
              </p>
            </div>
          </div>
          <section className="bg-white p-0 m-0">


            <div className="items-center flex flex-wrap my-24">
              <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
                <img alt="..." className="max-w-full rounded-lg shadow-xl" src={TruckPackages} /></div>
              <div className="w-full md:w-6/12 ml-auto px-12 md:px-4">
                <div className="md:pr-12">

                  <div className="text">
                    <h2 className="my-4 font-bold text-3xl  sm:text-4xl ">Chez <span className="text-orange-600">Logiteam</span>
                    </h2>

                    <div className="flex items-center text-lg">
                      {/* <span className="mx-4 text-gray-700 dark:text-gray-300">
                  nous sommes passionnés par l'offre d'une expérience de livraison exceptionnelle. Nous sommes convaincus que nos points relais sont le moyen idéal pour faciliter vos envois et garantir votre satisfaction. Nous nous efforçons de créer une relation de confiance avec nos clients en fournissant des services fiables et pratiques.
                  </span> */}


                      <div className="">
                        <div className="flex items-center mt-10 mb-10">
                          <span className="bg-orange-600 rounded-full p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                            </svg>
                          </span>
                          <p className="mx-4 text-gray-600">nous sommes passionnés par l'offre d'une expérience de livraison exceptionnell.e</p>
                        </div>
                        <div className="flex items-center mt-10 mb-10">
                          <span className="bg-orange-600 rounded-full p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                            </svg>
                          </span>
                          <p className="mx-4 text-gray-600">Nous sommes convaincus que nos points relais sont le moyen idéal pour faciliter vos envois et garantir votre satisfaction.</p>


                        </div>

                        <div className="flex items-center ">
                          <span className="bg-orange-600 rounded-full p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                            </svg>
                          </span>
                          <p className="mx-4 text-gray-600">Nous nous efforçons de créer une relation de confiance avec nos clients en fournissant des services fiables et pratiques.</p>


                        </div>
                      </div>

                    </div>
                    {/* <div className="grid grid-cols-1 sm:grid-cols-2 rounded gap-10  px-8">
                <div className="flex items-center mt-10 text-lg">
                <span className=" bg-orange-400 text-white p-3 rounded-full">
                <svg width="10" height="10" className=" w-5 h-5"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
            </span>
                  <span className="mx-4 font-bold">+216 27 341 038</span>
                </div>

                <div className="flex items-center mt-10 text-lg">
                <span className=" bg-orange-400 text-white p-3 rounded-full">
                <svg width="10" height="10" className=" w-5 h-5"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
            </span>
                  <span className="mx-4 font-bold">+216 29 430 430</span>
                </div>
                </div> */}
                  </div>
                </div></div>
            </div>
            <section className="py-24 mt-0  relative  bg-center bg-cover">
              <div className="absolute inset-0 bg-slate-50"></div>
              <div className="relative">
                <div className="relative flex flex-col items-center mx-auto lg:flex-row-reverse lg:max-w-5xl xl:max-w-6xl">

                  <div className="w-full h-64 lg:w-1/2 lg:h-auto">
                    <img className="h-full w-full object-cover" src={image} alt="" />
                  </div>

                  <div
                    className="max-w-lg bg-white md:max-w-2xl md:z-10 md:shadow-lg md:absolute md:top-0 md:mt-48 lg:w-3/5 lg:left-0 lg:mt-20 lg:ml-20 xl:mt-24 xl:ml-12">
                    <div className="flex flex-col p-12 md:px-16">
                      <div className="">
                        <div className="flex items-center mt-10 mb-10">
                          <span className="bg-blue-600 rounded-full p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                            </svg>
                          </span>
                          <p className="mx-4 text-gray-600">Rejoignez-nous dès aujourd'hui et découvrez les avantages d'utiliser notre réseau de points relais.
                            Simplifiez vos livraisons, économisez du temps et offrez une expérience client exceptionnelle.</p>
                        </div>
                        <div className="flex items-center ">
                          <span className="bg-blue-600 rounded-full p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                            </svg>
                          </span>
                          <p className="mx-4 text-gray-600">Travailler avec Logiteam, c'est faire le choix d'une logistique de colis efficace et adaptée à vos besoins.</p>


                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </section>



            <section className=" pt-24 pb-36">
              <div className="container mx-auto px-4">
                <div className="max-w-2xl mx-auto text-center">
                  <h2 className="text-3xl font-bold mb-6">Rejoignez-nous dès aujourd'hui</h2>
                  <p className="text-gray-600 mb-12">Rejoignez-nous dès aujourd'hui et découvrez les avantages d'utiliser notre réseau de points relais.
                    Simplifiez vos livraisons, économisez du temps et offrez une expérience client exceptionnelle.
                  </p>
                </div>


                <div className="grid grid-cols-1 sm:grid-cols-2 rounded gap-10  px-8">
                  <div className=" p-6  text-black rounded shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:rounded sm:px-10">

                    <div className="flex gap-4 items-start flex-col ">
                      <span className=" bg-orange-400 text-white p-3 rounded-full">
                        <svg width="20" height="20" className=" w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"></path>
                        </svg>
                      </span>
                      <div>
                        <h3 className="text-lg font-bold mb-4">Proximité et commodité</h3>
                        <p className=" leading-6 text-gray-600">
                          Avec nos 53 points relais stratégiquement répartis, il y en a forcément un près de chez vous. Plus besoin de faire de longs trajets pour récupérer ou déposer votre colis. Nos points relais sont facilement accessibles, ce qui vous permet de gagner du temps et de l'énergie.</p>
                      </div>
                    </div>
                  </div>



                  <div className=" p-6  text-black rounded shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:rounded sm:px-10">

                    <div className="flex gap-4 items-start flex-col ">
                      <span className=" bg-orange-400 text-white p-3 rounded-full">
                        <svg width="20" height="20" className=" w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                      </span>
                      <div>
                        <h3 className="text-lg font-bold mb-4">Flexibilité horaire</h3>
                        <p className=" leading-6 text-gray-600">
                          Nous comprenons que vos journées sont bien remplies. Nos points relais offrent des horaires d'ouverture étendus,
                          vous permettant ainsi de récupérer ou déposer vos colis à un moment qui vous convient le mieux.
                          Fini le stress des livraisons manquées ou des bureaux de poste fermés !</p>
                      </div>
                    </div>
                  </div>




                  <div className=" p-6  text-black rounded shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:rounded sm:px-10">

                    <div className="flex gap-4 items-start flex-col ">
                      <span className=" bg-orange-400 text-white p-3 rounded-full">
                        <svg width="20" height="20" className=" w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"></path>
                        </svg>
                      </span>
                      <div>
                        <h3 className="text-lg font-bold mb-4">Sécurité et confidentialité</h3>
                        <p className=" leading-6 text-gray-600">
                          Vos colis sont en sécurité dans nos points relais. Ils sont entreposés dans des endroits spécialement dédiés,
                          garantissant leur protection contre les dommages et les vols éventuels.
                          De plus, nous respectons la confidentialité de vos envois, assurant ainsi la tranquillité d'esprit pour vous et vos clients.</p>
                      </div>
                    </div>
                  </div>



                  <div className=" p-6  text-black rounded shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:rounded sm:px-10">

                    <div className="flex gap-4 items-start flex-col ">
                      <span className=" bg-orange-400 text-white p-3 rounded-full">
                        <svg width="20" height="20" className=" w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                        </svg>
                      </span>
                      <div>
                        <h3 className="text-lg font-bold mb-4">Souplesse des options de livraison</h3>
                        <p className=" leading-6 text-gray-600">
                          Vous avez la possibilité de choisir le point relais qui vous convient le mieux lors de la commande de vos produits en ligne.</p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </section>


          </section>


          <section className="py-24 mt-0 relative bg-[url('../images/contact2.png')] bg-center bg-cover">
            <div className="absolute inset-0 bg-black opacity-80"></div>
            <div className="relative">
              <div className="grid grid-cols-1 pb-8 text-center">
                {/* <h3 className="mb-6 md:text-3xl text-2xl text-white font-medium">Contactez-nous</h3>

                    <p className="text-white opacity-50 max-w-xl text-xl mx-auto"> Contactez-nous dès aujourd'hui pour discuter de vos besoins spécifiques et découvrez comment nos services peuvent vous aider à optimiser vos opérations logistiques. Faites confiance à Logiteam pour une logistique de colis fiable, rapide et sûre !</p> */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-20">
                  <div >
                    <div className="w-full">
                      <div className="bg-white p-10 rounded-lg shadow">
                        <div>
                          <div className="flex flex-wrap -m-2">
                            <div className="p-2 w-1/2">
                              <div className="relative">
                                <label
                                  htmlFor="name"
                                  className="leading-7 text-sm text-gray-600"
                                >
                                  Nom
                                </label>
                                <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                  onChange={(e) => {
                                    setContact((prevFormData) => ({
                                      ...prevFormData,
                                      name: e.target.value
                                    }));
                                  }}
                                />
                              </div>
                            </div>
                            <div className="p-2 w-1/2">
                              <div className="relative">
                                <label
                                  htmlFor="email"
                                  className="leading-7 text-sm text-gray-600"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                  onChange={(e) => {
                                    setContact((prevFormData) => ({
                                      ...prevFormData,
                                      email: e.target.value
                                    }));
                                  }}

                                />
                              </div>
                            </div>
                            <div className="p-2 w-full">
                              <div className="relative">
                                <label
                                  htmlFor="message"
                                  className="leading-7 text-sm text-gray-600"
                                >
                                  Message
                                </label>
                                <textarea
                                  id="message"
                                  name="message"
                                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-200 h-32 text-lg outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                                  onChange={(e) => {
                                    setContact((prevFormData) => ({
                                      ...prevFormData,
                                      message: e.target.value
                                    }));
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="p-2 w-full">
                              {/* <button onClick={handleSubmit} className="flex mx-auto text-white bg-orange-500 border-0 py-2 px-8 focus:outline-none hover:bg-orange-600 rounded text-lg">
                  Envoyer
                </button> */}
                              <button onClick={handleSubmit} className='flex mx-auto text-white border-0 py-2 px-8 focus:outline-none  group font-medium tracking-wide select-none text-base relative  justify-center cursor-pointer h-12 border-2 border-solid rounded-md overflow-hidden z-10 transition-all duration-300 ease-in-out outline-0 bg-orange-500 text-white border-orange-500 hover:text-orange-500 focus:text-orange-500'>
                                <strong className='font-medium'>Contact</strong>
                                <span className='absolute bg-white bottom-0 w-0 left-1/2 h-full -translate-x-1/2 transition-all ease-in-out duration-300 group-hover:w-[105%] -z-[1] group-focus:w-[105%]'></span>
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-white rounded">
                    <h2 className="font-bold text-2xl">Contactez-nous</h2>
                    <ul className="list-disc mt-4 list-inside">
                      Contactez-nous dès maintenant pour discuter de la façon dont nous pouvons vous aider à optimiser vos opérations de livraison et à offrir un service de qualité à vos clients.
                    </ul>
                    <div className="grid grid-cols-1 sm:grid-cols-2 rounded gap-10">
                      <div className="flex items-center mt-10 text-lg">
                        <span className=" bg-orange-500 text-white p-3 rounded-full">
                          <svg width="10" height="10" className=" w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
                        </span>
                        <span className="mx-4 font-bold">+216 27 341 038</span>
                      </div>

                      <div className="flex items-center mt-10 text-lg">
                        <span className=" bg-orange-500 text-white p-3 rounded-full">
                          <svg width="10" height="10" className=" w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
                        </span>
                        <span className="mx-4 font-bold">+216 29 430 430</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


        </div>
      </section>

    </>
  );
};

export default Presentation;
