import axios, { AxiosResponse } from "axios";
import { contact } from "../models/_model.contact";

const CONTACT_URL = `https://dev-api.logiteam.tn/api/send-email`;

const createContact = (contact: contact): Promise<contact> => {
  return axios
    .post(`${CONTACT_URL}`, contact)
    .then(
      (response: AxiosResponse<contact>) => response.data
    );
};




export { createContact };
