import parcels from "../images/parcels.jpg";
import { BiRightArrowAlt } from "react-icons/bi";

const ServicesIntroduction = () => {
  return (
    <div className="flex h-fit bg-gray-100 sm:m-4 rounded-lg shadow-lg">
      <div className="m-6 grid grid-cols-2 gap-8 overflow-hidden px-4 sm:py-8 sm:px-6 md:py-10 lg:px-8">
        <div className="box col-span-2 md:col-span-1">
          <h1 className="col-span text-center sm:text-start text-5xl font-bold text-orange-500 max-h-fit">
            Pour tous vos besoins en matière d’expédition de colis
          </h1>
          <p className="col-span mt-2 text-center sm:text-start text-3xl hover:text-4xl text-cyan-900 ">
            un coup d'oeil sur notre entreprise
          </p>
        </div>
        <div className="box col-span-2 text-center">
          <p className="text-cyan-800 text-xl">
            Logiteam est une entreprise de logistique de colis utilise des
            points relais pour assurer une livraison rapide et sûre de vos
            envois. Avec plus de 50 points relais à travers le pays, de la
            région Nord à l'Ouest, nous garantissons une livraison maximale de
            72 heures. Nous nous engageons à assurer la sécurité de vos colis
            tout au long de leur voyage pour vous offrir une tranquillité
            d'esprit.
          </p>
          <div className="my-4 text-end w-full">
            <a href='/about' className='group font-medium tracking-wide select-none text-base relative inline-flex items-center justify-center cursor-pointer h-12 border-2 border-solid py-0 px-6 rounded-md overflow-hidden z-10 transition-all duration-300 ease-in-out outline-0 bg-orange-500 text-white border-orange-500 hover:text-orange-500 focus:text-orange-500'>
<strong className='font-medium'>En savoir plus</strong>
<svg className="ml-1 rotate-180 fill-white group-hover:fill-orange-500" width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4"
        d="M17.6954 12.4962L21.6468 12.1467C22.5335 12.1467 23.2525 12.8727 23.2525 13.7681C23.2525 14.6635 22.5335 15.3895 21.6468 15.3895L17.6954 15.04C16.9997 15.04 16.4357 14.4705 16.4357 13.7681C16.4357 13.0645 16.9997 12.4962 17.6954 12.4962"
        ></path>
    <path d="M4.42637 12.5604C4.48813 12.4981 4.71885 12.2345 4.93559 12.0157C6.19989 10.6449 9.50107 8.40347 11.228 7.71751C11.4902 7.60808 12.1532 7.37512 12.5086 7.35864C12.8477 7.35864 13.1716 7.43748 13.4804 7.59279C13.8661 7.81046 14.1738 8.15403 14.3439 8.55878C14.4522 8.83882 14.6224 9.68009 14.6224 9.69539C14.7913 10.6143 14.8834 12.1086 14.8834 13.7606C14.8834 15.3325 14.7913 16.7656 14.6527 17.6999C14.6375 17.7163 14.4674 18.76 14.2821 19.1177C13.943 19.7719 13.28 20.1766 12.5704 20.1766H12.5086C12.046 20.1613 11.0742 19.7554 11.0742 19.7413C9.43931 19.0553 6.21621 16.9221 4.92044 15.5043C4.92044 15.5043 4.55455 15.1396 4.39608 14.9125C4.14904 14.5854 4.02552 14.1806 4.02552 13.7759C4.02552 13.3241 4.16419 12.904 4.42637 12.5604"
        ></path>
</svg>
<span className='absolute bg-white bottom-0 w-0 left-1/2 h-full -translate-x-1/2 transition-all ease-in-out duration-300 group-hover:w-[105%] -z-[1] group-focus:w-[105%]'></span>
</a>
          </div>
        </div>
      </div>
      <div className=" hidden md:block">
        <img
          src={parcels}
          alt="introduction-side-img"
          className="rounded-r-lg object-cover h-full w-full"
        />
      </div>
    </div>
  );
};

export default ServicesIntroduction;