/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRelayPoints } from "../core/apis/_requests.relays";
import { getTracking } from "../core/apis/_requests.tracking";
import { Options } from "../core/models/_models.general";
import { RelayPoint } from "../core/models/_models.relay";
import { setRelayPoints } from "../redux/features/relays";
import { setResultTracking } from "../redux/features/tracking";
import Select from "react-select";

type Props = {
  className?: string;
  id: string | null;
};

export const TrackingSearch: FC<Props> = ({ className, id }) => {
  const dispatch = useDispatch();
  const [relaySearch, setRelaySearch] = useState<string>("");
  const [trackingNumber, setTrackingNumber] = useState<string>("");
  const [options, setOptions] = useState<Options>([]);
  const relayState = useSelector((state: any) => state.relays.relayState);

  const handleTrack = async (id: string) => {
    await getTracking(id).then((res) => {
      dispatch(setResultTracking(res));
    });
  };

  const getAllRelays = async () => {
    await getRelayPoints().then((res) => {
      dispatch(setRelayPoints(res));
    });
  };

  useEffect(() => {
    getAllRelays();
  }, []);

  useEffect(() => {
    if (id) {
      handleTrack(id);
    }
  }, [id]);

  const handleSearchRelay = (e: any) => {
    e.preventDefault();
    if (relaySearch && relaySearch.length > 0) {
      window.open(
        "https://www.google.com/maps/search/graiet+" + relaySearch.substring(5)
      );
    } else {
      window.open("https://www.google.com/maps/search/graiet+magasin");
    }
  };

  useEffect(() => {
    const options: Options = [];
    relayState?.map((item: RelayPoint) =>
      options.push({
        value: item.id!,
        label: item.postal_code + " " + item.city + "-" + item.governorate,
      })
    );
    setOptions(options);
  }, [relayState]);

  return (
    <div className={className}>
     </div>
  );
};

// <div className="relative grid grid-cols-5 gap-3 rounded-md bg-white shadow-md ">
//   <div className="sm:col-span-1 sm:block hidden p-4">
//     <h2 className="text-cyan-600">Suivi colis</h2>
//   </div>
//   <div className="sm:col-span-4 col-span-5 grid grid-rows-2 sm:grid-cols-3 sm:grid-rows-1  gap-4 p-4">
//     <input
//       type="text"
//       className="row-span sm:col-span-2 rounded-md border-2 border-cyan-600 p-2"
//       placeholder="Entrer votre numéro de suivi"
//       onChange={(e) => {
//         setTrackingNumber(e.target.value);
//       }}
//     />
//     <button
//       className="row-span sm:col-span rounded-md border-2 bg-orange-500 p-2"
//       onClick={() => handleSearch(trackingNumber)}
//     >
//       Search
//     </button>
//   </div>
// </div>;
